import React, { useState } from "react";
import emoji from "react-easy-emoji";
import "./ToggleSwitch.css";

const ToggleSwitch = () => {
  const [isChecked, setChecked] = useState(false);

  const handleToggle = () => {
    setChecked(!isChecked);
    document.body.classList.toggle("dark-mode", !isChecked); // Toggle dark mode class on the body
  };

  return (
    <label className="switch">
      {/* <input
        type="checkbox"
        checked={isChecked}
        onChange={handleToggle}
      /> */}
      <span className="slider round">
        <span className="emoji">{isChecked ? emoji("☀️") : emoji("🌜")}</span>
      </span>
    </label>
  );
};

export default ToggleSwitch;
