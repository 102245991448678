import React from 'react';
import { Helmet } from 'react-helmet';
import Tilt from 'react-parallax-tilt';
import Fade from 'react-reveal/Fade';

const GlowingImage = () => {
  const animationKeyframes = `
    @keyframes borderFlow {
      0% { background-position: 0% 50%; }
      50% { background-position: 100% 50%; }
      100% { background-position: 0% 50%; }
    }
  `;

  const imageContainerStyle = {
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
    background: 'linear-gradient(90deg, rgba(255, 0, 150, 0.6), rgba(0, 255, 255, 0.6), rgba(0, 150, 255, 0.6), rgba(255, 255, 0, 0.6), rgba(0, 255, 150, 0.6))',
    backgroundSize: '400% 400%',
    borderRadius: '20px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
    animation: 'borderFlow 8s linear infinite',
    position: 'relative',
    width: '300px', // Fixed width
    height: '300px', // Fixed height
  };

  const imageStyle = {
    borderRadius: '15px',
    transition: 'transform 0.3s, box-shadow 0.3s',
    width: '100%', // Ensures image fits container
    height: '100%', // Ensures image fits container
    objectFit: 'cover', // Maintains aspect ratio of image
  };

  const tiltStyle = {
    borderRadius: '15px',
    overflow: 'hidden',
    position: 'relative',
    width: '100%', // Matches container width
    height: '100%', // Matches container height
  };

  const handleMouseEnter = (e) => {
    e.target.style.transform = 'scale(1.05)';
    e.target.style.boxShadow = '0 0 40px 10px rgba(255, 0, 0, 1), 0 0 60px 20px rgba(0, 255, 0, 1), 0 0 80px 30px rgba(0, 0, 255, 1)';
  };

  const handleMouseLeave = (e) => {
    e.target.style.transform = 'scale(1)';
    e.target.style.boxShadow = '0 0 20px 10px rgba(255, 0, 0, 0.8), 0 0 40px 20px rgba(0, 255, 0, 0.8), 0 0 60px 30px rgba(0, 0, 255, 0.8)';
  };

  return (
    <>
    <Helmet>
        <title>Azay - Software Developer </title>
        <meta name="description" content="Azay, Ajay kumar Yadav, Azay Yadav , Software Developer , Web Development, Full SEO,CSE Developer Nepal,Software Developer Nepal ,Ajay Kumar Yadav Developer" />
        <meta name="keywords" content="Azay, Ajay kumar Yadav, Azay Yadav , Software Developer , Web Development, Full SEO,CSE Developer Nepal,Software Developer Nepal ,Ajay Kumar Yadav Developer
Azay Yadav Portfolio ,Developer from Janakpurdham , Nepalese Software Developer , Full Stack Developer Nepal
,Janakpurdham IT Professional ,React Developer Nepal, Web Developer Janakpurdham, Mobile App Developer Nepal, Software Engineer Nepal, 
Janakpur CSE Developer, Frontend Developer Nepal, Backend Developer Nepal, AI Developer Nepal, Python Developer Nepal,
 JavaScript Developer Nepal, Tech Enthusiast Nepal, Azay Yadav CSE, Ajay Kumar Yadav Developer Nepal, CSE Graduate Developer
, App Developer Nepal, Website Developer Janakpur, Full Stack Engineer Nepal, React Native Developer Nepal, AI Enthusiast Nepal, Software Solutions Nepal, Freelance Developer Nepal, 
Developer from Janakpur, Portfolio Developer Nepal, Nepal IT Projects, Software Development Nepal, Web Application Developer Nepal, Mobile Applications Nepal, Cloud Developer Nepal, Software Developer Janakpur
, Tech Innovator Nepal, IT Consultant Nepal, AI Projects Nepal, Python Expert Nepal, React Developer Portfolio, CSE Software Developer, 
IT Projects Nepal, Azay Yadav Nepal, Janakpurdham Developer Portfolio, Full Stack Web Developer
, Tech Developer Nepal, Software Engineer from Nepal, AI Researcher Nepal , CSE Developer Nepal, Software Developer Nepal, Ajay Kumar Yadav Developer, Azay Yadav Portfolio, 
Developer from Janakpurdham, Nepalese Software Developer, Full Stack Developer Nepal, Janakpurdham IT Professional, React Developer Nepal, 
Web Developer Janakpurdham, Mobile App Developer Nepal, Software Engineer Nepal, Janakpur CSE Developer, Frontend Developer Nepal, 
Backend Developer Nepal, AI Developer Nepal, Python Developer Nepal, JavaScript Developer Nepal, IEEE IoT Research Nepal, IoT Developer Nepal, 
Speech Emotion Recognition Developer, Machine Learning Developer Nepal, CSE Graduate Developer, App Developer Nepal, Website Developer Janakpur, 
React Native Developer Nepal, AI Enthusiast Nepal, Software Solutions Nepal, Freelance Developer Nepal, Developer from Janakpur,
 Portfolio Developer Nepal, Nepal IT Projects, Speech Emotion Recognition Research, IoT Projects Nepal, Full Stack Engineer Nepal, 
 Cloud Developer Nepal, Software Developer Janakpur, Tech Innovator Nepal, IT Consultant Nepal, React Developer Portfolio, Python Expert Nepal,
  J2EE Developer Nepal, Machine Learning Projects Nepal, .NET Developer Nepal, HTML CSS JavaScript Developer, 
  IEEE Paper Speech Emotion Recognition, Web Application Developer Nepal, Research on IoT Nepal, Full Stack Web Developer,
   Java Developer Nepal." />
        
        
       <title>Azay Yadav - Software Developer </title>
       <meta name="description" content="Ajay Kumar Yadav,  a developer with a Computer Science background.blog updates Soon." />
       <meta name="keywords" content="CSE Developer, Ajay Kumar Yadav, Projects, About, Resume, Home, Blog coming soon, Developer Portfolio" />
  
       {/* Open Graph Tags for Social Sharing */}
       <meta property="og:title" content="Azay Yadav - CSE Background Developer Azay - Profile " />
       <meta property="og:description" content="Ajay Kumar Yadav, also known as Azay Yadav, a developer with a Computer Science background. Explore my projects, resume, and upcoming blog." />
        <meta property="og:url" content="https://codewithazay.online/" />
        <meta property="og:image" content="https://codewithazay.online"/>

        {/* Twitter Cards for Social Media */}
       <meta name="twitter:card" content="summary_large_image" />
       <meta name="twitter:title" content="Azay Yadav - Azay - Profile  " />
       <meta name="twitter:description" content="Ajay Kumar Yadav, also known as Azay Yadav, developer portfolio featuring projects, resume, and blog updates." />
       <meta name="twitter:image" content="https://codewithazay.online" />

   
        </Helmet>

      <style>
        {animationKeyframes}
      </style>
      <div style={imageContainerStyle}>
        <Tilt style={tiltStyle}>
          <Fade cascade>
            <img
              src="./profile.png"
              alt="home pic"
              style={{
                ...imageStyle,
                boxShadow: '0 0 20px 10px rgba(255, 0, 0, 0.8), 0 0 40px 20px rgba(0, 255, 0, 0.8), 0 0 60px 30px rgba(0, 0, 255, 0.8)',
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className="img-fluid"
            />
          </Fade>
        </Tilt>
      </div>
    </>
  );
};

export default GlowingImage;
