import React from 'react';
import Particles from 'react-tsparticles';

const Particle = () => {
    return (
        <Particles
            params={{
                particles: {
                    number: {
                        value: 40,
                        density: {
                            enable: true,
                            value_area: 500,
                        },
                    },
                    shape: {
                        type: ["star"],
                        polygon: {
                            nb_sides: 5,
                        },
                    },
                    color: {
                        value: [
                            "#ff5733", "#ff1493", "#8a2be2", "#33ff57", "#ff007f", 
                            "#0000ff", "#ffffff", "#ff0000", "#007fff", "#00ff00", 
                            "#57ffdd", "#ffdd57", "#ff00ff"
                        ],
                    },
                    opacity: {
                        value: 0.8,
                        random: true,
                        anim: {
                            enable: false, // Disable animation for opacity change
                        },
                    },
                    size: {
                        value: 4,
                        random: true,
                        anim: {
                            enable: false, // Disable size animation to prevent auto-increase
                        },
                    },
                    line_linked: {
                        enable: true,
                        distance: 100,
                        color: "#ffffff",
                        opacity: 0.5,
                        width: 1,
                    },
                    move: {
                        enable: true,
                        speed: 0.5, // Fixed speed for smooth movement
                        direction: "none",
                        random: true,
                        straight: false, // Ensure particles move randomly, not straight
                        out_mode: "out",
                        bounce: false, // No bouncing effect
                        attract: {
                            enable: true,
                            rotateX: 100,
                            rotateY: 100,
                        },
                    },
                },
                interactivity: {
                    events: {
                        onhover: {
                            enable: true,
                            mode: "repulse",
                        },
                        onclick: {
                            enable: true,
                            mode: "push",
                        },
                        resize: true,
                    },
                    modes: {
                        grab: {
                            distance: 100,
                            line_linked: {
                                opacity: 0.5,
                            },
                        },
                        repulse: {
                            distance: 100,
                            duration: 0.4,
                        },
                        push: {
                            particles_nb: 4,
                        },
                    },
                },
                retina_detect: true,
            }}
            style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                zIndex: -1,
            }}
        />
    );
}

export default Particle;
