import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Zoom from "react-reveal/Zoom";
import { Fade } from "react-reveal";
import ucsc from "../../images/ucsc.png";
import GeeksforGeeks from "../../images/GeeksforGeeks.jpeg";
import Cisco from "../../images/Cisco.png";
import CCNAITN from "../../images/CCNAITN.png";
import IBM from "../../images/IBM.png";
import CyberEssentials from "../../images/CyberEssentials.png"
import CiscoN from "../../images/CiscoN.png"
import Coursera from "../../images/Coursera.png";
import Particle from "../../Particle";
import AboutSection from "../../components/Animated/AboutSection";
import { Helmet } from "react-helmet";

export default function CertificatePage() {
  return (
    <>
    
    <section className="home-section">
      <Container fluid id="home">
        <Particle />
        
        <Container className="home-content">
        <Helmet>
        <title>Azay Yadav - certificates</title>
        <meta name="description" content="App Developer Nepal, Website Developer Janakpur, Full Stack Engineer Nepal, React Native Developer Nepal, AI Enthusiast Nepal, Software Solutions Nepal, Freelance Developer Nepal, " />
        <meta name="keywords" content="Azay, Ajay kumar Yadav, Azay Yadav , Software Developer , Web Development, Full SEO,CSE Developer Nepal,Software Developer Nepal ,Ajay Kumar Yadav Developer
Azay Yadav Portfolio ,Developer from Janakpurdham , Nepalese Software Developer , Full Stack Developer Nepal
,Janakpurdham IT Professional ,React Developer Nepal, Web Developer Janakpurdham, Mobile App Developer Nepal, Software Engineer Nepal, 
Janakpur CSE Developer, Frontend Developer Nepal, Backend Developer Nepal, AI Developer Nepal, Python Developer Nepal,
 JavaScript Developer Nepal, Tech Enthusiast Nepal, Azay Yadav CSE, Ajay Kumar Yadav Developer Nepal, CSE Graduate Developer
, App Developer Nepal, Website Developer Janakpur, Full Stack Engineer Nepal, React Native Developer Nepal, AI Enthusiast Nepal, Software Solutions Nepal, Freelance Developer Nepal, 
Developer from Janakpur, Portfolio Developer Nepal, Nepal IT Projects, Software Development Nepal, Web Application Developer Nepal, Mobile Applications Nepal, Cloud Developer Nepal, Software Developer Janakpur
, Tech Innovator Nepal, IT Consultant Nepal, AI Projects Nepal, Python Expert Nepal, React Developer Portfolio, CSE Software Developer, 
IT Projects Nepal, Azay Yadav Nepal, Janakpurdham Developer Portfolio, Full Stack Web Developer
, Tech Developer Nepal, Software Engineer from Nepal, AI Researcher Nepal , CSE Developer Nepal, Software Developer Nepal, Ajay Kumar Yadav Developer, Azay Yadav Portfolio, 
Developer from Janakpurdham, Nepalese Software Developer, Full Stack Developer Nepal, Janakpurdham IT Professional, React Developer Nepal, 
Web Developer Janakpurdham, Mobile App Developer Nepal, Software Engineer Nepal, Janakpur CSE Developer, Frontend Developer Nepal, 
Backend Developer Nepal, AI Developer Nepal, Python Developer Nepal, JavaScript Developer Nepal, IEEE IoT Research Nepal, IoT Developer Nepal, 
Speech Emotion Recognition Developer, Machine Learning Developer Nepal, CSE Graduate Developer, App Developer Nepal, Website Developer Janakpur, 
React Native Developer Nepal, AI Enthusiast Nepal, Software Solutions Nepal, Freelance Developer Nepal, Developer from Janakpur,
 Portfolio Developer Nepal, Nepal IT Projects, Speech Emotion Recognition Research, IoT Projects Nepal, Full Stack Engineer Nepal, 
 Cloud Developer Nepal, Software Developer Janakpur, Tech Innovator Nepal, IT Consultant Nepal, React Developer Portfolio, Python Expert Nepal,
  J2EE Developer Nepal, Machine Learning Projects Nepal, .NET Developer Nepal, HTML CSS JavaScript Developer, 
  IEEE Paper Speech Emotion Recognition, Web Application Developer Nepal, Research on IoT Nepal, Full Stack Web Developer,
   Java Developer Nepal." />
        
        
       <title>Azay Yadav - certificates</title>
       <meta name="description" content="Ajay Kumar Yadav,  a developer with a Computer Science background.blog updates Soon." />
       <meta name="keywords" content="CSE Developer, Ajay Kumar Yadav, Projects, About, Resume, Home, Blog coming soon, Developer Portfolio" />
  
       {/* Open Graph Tags for Social Sharing */}
       <meta property="og:title" content="Azay Yadav - CSE Background Developer Portfolio" />
       <meta property="og:description" content="Ajay Kumar Yadav, also known as Azay Yadav, a developer with a Computer Science background. Explore my projects, resume, and upcoming blog." />
        <meta property="og:url" content="https://codewithazay.online/" />
        <meta property="og:image" content="https://codewithazay.online"/>

        {/* Twitter Cards for Social Media */}
       <meta name="twitter:card" content="Azay Yadav - Certificate" />
       <meta name="twitter:title" content="Azay Yadav - Certificate " />
       <meta name="twitter:description" content="Ajay Kumar Yadav, also known as Azay Yadav, developer portfolio featuring projects, resume, and blog updates." />
       <meta name="twitter:image" content="https://codewithazay.online" />

       {/* Canonical Tag */}
   
        </Helmet>

        <AboutSection></AboutSection>
          <div
            className="d-flex justify-content-center"
            width="100%"
            style={{ backgroundColor: "#fbd9ad" }}
          >
            <Zoom left cascade>
              <h1 style={{ color: "rgb(134 61 176" }}>Certificates</h1>
            </Zoom>
          </div>
          <div>
            <div>
              <Container fluid className="certificate-section" id="about">
                <Container>
                  <Row>
                    <Col md={12} className="mt-5">
                      <Row className="g-5">
                        <Col md={4} className="col-sm-12 col-md4">
                          <Fade bottom duration={2000} distance="20px">
                            <div className="cert-card">
                              <div className="content">
                                <a
                                  href="https://www.hackerrank.com/certificates/1787d8d7c926"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div className="content-overlay"></div>
                                  <div
                                    className="cert-header"
                                    style={{ backgroundColor: "white" }}
                                  >
                                    {
                                      <img
                                        className="logo_img"
                                        src={ucsc}
                                        alt={ucsc}
                                      />
                                    }
                                  </div>
                                  <div className="content-details fadeIn-top">
                                    <h3
                                      className="content-title"
                                      style={{ color: "black" }}
                                    >
                                      Certificate
                                    </h3>
                                  </div>
                                </a>
                              </div>
                              <div className="cert-body">
                                <h2
                                  className="cert-body-title"
                                  style={{ fontWeight: 700, color: "#fbd9ad" }}
                                >
                                  Problem Solving (Intermediate)
                                </h2>
                                <h3
                                  className="cert-body-subtitle"
                                  style={{
                                    color: "#eb90ff",
                                    marginBottom: "0px",
                                  }}
                                >
                                  - HackerRank
                                </h3>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                        <Col md={4}>
                          <Fade bottom duration={2000} distance="20px">
                            <div className="cert-card">
                              <div className="content">
                                <a
                                  href={"https://drive.google.com/file/d/1LbBzwMcB6A9R274y0vpyGt96h8uO5Wxs/view?usp=sharing"}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div className="content-overlay"></div>
                                  <div
                                    className="cert-header"
                                    style={{ backgroundColor: "white" }}
                                  >
                                    {
                                      <img
                                        className="logo_img"
                                        src={GeeksforGeeks}
                                        alt={GeeksforGeeks}
                                      />
                                    }
                                  </div>
                                  <div className="content-details fadeIn-top">
                                    <h3
                                      className="content-title"
                                      style={{ color: "black" }}
                                    >
                                      Certificate
                                    </h3>
                                  </div>
                                </a>
                              </div>
                              <div className="cert-body">
                                <h2
                                  className="cert-body-title"
                                  style={{ fontWeight: 700, color: "#fbd9ad" }}
                                >
                                | Technical Content Writer | Intern |
                                </h2>
                                <h3
                                  className="cert-body-subtitle"
                                  style={{
                                    color: "#eb90ff",
                                    marginBottom: "0px",
                                  }}
                                >
                                  GeeksforGeeks
                                </h3>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                        <Col md={4}>
                          <Fade bottom duration={2000} distance="20px">
                            <div className="cert-card">
                              <div className="content">
                                <a
                                  href="https://www.hackerrank.com/certificates/3eb60c3e8c58"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div className="content-overlay"></div>
                                  <div
                                    className="cert-header"
                                    style={{ backgroundColor: "white" }}
                                  >
                                    {
                                      <img
                                        className="logo_img"
                                        src={ucsc}
                                        alt={ucsc}
                                      />
                                    }
                                  </div>
                                  <div className="content-details fadeIn-top">
                                    <h3
                                      className="content-title"
                                      style={{ color: "black" }}
                                    >
                                      Certificate
                                    </h3>
                                  </div>
                                </a>
                              </div>
                              <div className="cert-body">
                                <h2
                                  className="cert-body-title"
                                  style={{ fontWeight: 700, color: "#fbd9ad" }}
                                >
                                  C# (Basic)
                                </h2>
                                <h3
                                  className="cert-body-subtitle"
                                  style={{
                                    color: "#eb90ff",
                                    marginBottom: "0px",
                                  }}
                                >
                                  - HackerRank
                                </h3>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                        <Col md={4}>
                          <Fade bottom duration={2000} distance="20px">
                            <div className="cert-card">
                              <div className="content">
                                <a
                                  href={Cisco}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div className="content-overlay"></div>
                                  <div
                                    className="cert-header"
                                    style={{ backgroundColor: "white" }}
                                  >
                                    {
                                      <img
                                        className="logo_img"
                                        src={Cisco}
                                        alt={Cisco}
                                      />
                                    }
                                  </div>
                                  <div className="content-details fadeIn-top">
                                    <h3
                                      className="content-title"
                                      style={{ color: "black" }}
                                    >
                                      Certificate
                                    </h3>
                                  </div>
                                </a>
                              </div>
                              <div className="cert-body">
                                <h2
                                  className="cert-body-title"
                                  style={{ fontWeight: 700, color: "#fbd9ad" }}
                                >
                                  CCNA(Cisco Certified Network Associate) :
                                  Basic Networking for Everyone.
                                </h2>
                                <h3
                                  className="cert-body-subtitle"
                                  style={{
                                    color: "#eb90ff",
                                    marginBottom: "0px",
                                  }}
                                >
                                  - Cisco Certified Network Associate
                                </h3>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                        <Col md={4}>
                          <Fade bottom duration={2000} distance="20px">
                            <div className="cert-card">
                              <div className="content">
                                <a
                                  href={"https://drive.google.com/file/d/1NN-iHM82rVDmVEh1sctERwvrA5m5Ak3b/view?usp=sharing"}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div className="content-overlay"></div>
                                  <div
                                    className="cert-header"
                                    style={{ backgroundColor: "white" }}
                                  >
                                    {
                                      <img
                                        className="logo_img"
                                        src={Coursera}
                                        alt={Coursera}
                                      />
                                    }
                                  </div>
                                  <div className="content-details fadeIn-top">
                                    <h3
                                      className="content-title"
                                      style={{ color: "black" }}
                                    >
                                      Certificate
                                    </h3>
                                  </div>
                                </a>
                              </div>
                              <div className="cert-body">
                                <h2
                                  className="cert-body-title"
                                  style={{ fontWeight: 700, color: "#fbd9ad" }}
                                >
                                 Introduction to Cloud Computing
                                </h2>
                                <h3
                                  className="cert-body-subtitle"
                                  style={{
                                    color: "#eb90ff",
                                    marginBottom: "0px",
                                  }}
                                >
                                  -IBM  COURSE CERTIFICATE
                                </h3>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                        <Col md={4}>
                          <Fade bottom duration={2000} distance="20px">
                            <div className="cert-card">
                              <div className="content">
                                <a
                                  href={"https://drive.google.com/file/d/1X2XIOn4X1IUX1K_7nnNjWPFRZo3Qrvco/view?usp=sharing"}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div className="content-overlay"></div>
                                  <div
                                    className="cert-header"
                                    style={{ backgroundColor: "white" }}
                                  >
                                    {
                                      <img
                                        className="logo_img"
                                        src={Coursera}
                                        alt={Coursera}
                                      />
                                    }
                                  </div>
                                  <div className="content-details fadeIn-top">
                                    <h3
                                      className="content-title"
                                      style={{ color: "black" }}
                                    >
                                      Certificate
                                    </h3>
                                  </div>
                                </a>
                              </div>
                              <div className="cert-body">
                                <h2
                                  className="cert-body-title"
                                  style={{ fontWeight: 700, color: "#fbd9ad" }}
                                >
                                  CCNA: Introduction to Networks
                                </h2>
                                <h3
                                  className="cert-body-subtitle"
                                  style={{
                                    color: "#eb90ff",
                                    marginBottom: "0px",
                                  }}
                                >
                                  - Issued by Cisco
                                </h3>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                        <Col md={4}>
                          <Fade bottom duration={2000} distance="20px">
                            <div className="cert-card">
                              <div className="content">
                                <a
                                  href="https://www.credly.com/earner/earned/badge/e9db6fa1-c0c1-4332-b740-53367e36df57"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div className="content-overlay"></div>
                                  <div
                                    className="cert-header"
                                    style={{ backgroundColor: "white" }}
                                  >
                                    {
                                      <img
                                        className="logo_img"
                                        src={CCNAITN}
                                        alt={CCNAITN}
                                      />
                                    }
                                  </div>
                                  <div className="content-details fadeIn-top">
                                    <h3
                                      className="content-title"
                                      style={{ color: "black" }}
                                    >
                                      Certificate
                                    </h3>
                                  </div>
                                </a>
                              </div>
                              <div className="cert-body">
                                <h2
                                  className="cert-body-title"
                                  style={{ fontWeight: 700, color: "#fbd9ad" }}
                                >
                                  Web Development with HTML, CSS, JavaScript Essentials
                                </h2>
                                <h3
                                  className="cert-body-subtitle"
                                  style={{
                                    color: "#eb90ff",
                                    marginBottom: "0px",
                                  }}
                                >
                                  - Issued by Coursera
                                  - Authorized by IBM
                                </h3>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                        <Col md={4}>
                          <Fade bottom duration={2000} distance="20px">
                            <div className="cert-card">
                              <div className="content">
                                <a
                                  href="https://www.credly.com/badges/f2ab5dea-370e-4374-8a55-72f9d09e0ba5"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div className="content-overlay"></div>
                                  <div
                                    className="cert-header"
                                    style={{ backgroundColor: "white" }}
                                  >
                                    {
                                      <img
                                        className="logo_img"
                                        src={IBM}
                                        alt={IBM}
                                      />
                                    }
                                  </div>
                                  <div className="content-details fadeIn-top">
                                    <h3
                                      className="content-title"
                                      style={{ color: "black" }}
                                    >
                                      Certificate
                                    </h3>
                                  </div>
                                </a>
                              </div>
                              <div className="cert-body">
                                <h2
                                  className="cert-body-title"
                                  style={{ fontWeight: 700, color: "#fbd9ad" }}
                                >
                                  Cybersecurity Essentials
                                </h2>
                                <h3
                                  className="cert-body-subtitle"
                                  style={{
                                    color: "#eb90ff",
                                    marginBottom: "0px",
                                  }}
                                >
                                  - Issued by Cisco
                                </h3>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                        <Col md={4}>
                          <Fade bottom duration={2000} distance="20px">
                            <div className="cert-card">
                              <div className="content">
                                <a
                                  href="https://www.credly.com/earner/earned/badge/01cffdb3-bbe6-4fd3-9d6b-eec224253518"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div className="content-overlay"></div>
                                  <div
                                    className="cert-header"
                                    style={{ backgroundColor: "white" }}
                                  >
                                    {
                                      <img
                                        className="logo_img"
                                        src={CyberEssentials}
                                        alt={CyberEssentials}
                                      />
                                    }
                                  </div>
                                  <div className="content-details fadeIn-top">
                                    <h3
                                      className="content-title"
                                      style={{ color: "black" }}
                                    >
                                      Certificate
                                    </h3>
                                  </div>
                                </a>
                              </div>
                              <div className="cert-body">
                                <h2
                                  className="cert-body-title"
                                  style={{ fontWeight: 700, color: "#fbd9ad" }}
                                >
                                  Software Engineer
                                </h2>
                                <h3
                                  className="cert-body-subtitle"
                                  style={{
                                    color: "#eb90ff",
                                    marginBottom: "0px",
                                  }}
                                >
                                  - HackerRank.
                                </h3>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                        <Col md={4}>
                          <Fade bottom duration={2000} distance="20px">
                            <div className="cert-card">
                              <div className="content">
                                <a
                                  href="https://www.hackerrank.com/certificates/e6a4bee5244c"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div className="content-overlay"></div>
                                  <div
                                    className="cert-header"
                                    style={{ backgroundColor: "white" }}
                                  >
                                    {
                                      <img
                                        className="logo_img"
                                        src={ucsc}
                                        alt={ucsc}
                                      />
                                    }
                                  </div>
                                  <div className="content-details fadeIn-top">
                                    <h3
                                      className="content-title"
                                      style={{ color: "black" }}
                                    >
                                      Certificate
                                    </h3>
                                  </div>
                                </a>
                              </div>
                              <div className="cert-body">
                                <h2
                                  className="cert-body-title"
                                  style={{ fontWeight: 700, color: "#fbd9ad" }}
                                >
                                 Software Engineer Intern
                                </h2>
                                <h3
                                  className="cert-body-subtitle"
                                  style={{
                                    color: "#eb90ff",
                                    marginBottom: "0px",
                                  }}
                                >
                                  - HackerRank.
                                </h3>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                        <Col md={4} className="col-sm-12 col-md4">
                          <Fade bottom duration={2000} distance="20px">
                            <div className="cert-card">
                              <div className="content">
                                <a
                                  href="https://www.hackerrank.com/certificates/7b3c4e1ea96f"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div className="content-overlay"></div>
                                  <div
                                    className="cert-header"
                                    style={{ backgroundColor: "white" }}
                                  >
                                    {
                                      <img
                                        className="logo_img"
                                        src={ucsc}
                                        alt={ucsc}
                                      />
                                    }
                                  </div>
                                  <div className="content-details fadeIn-top">
                                    <h3
                                      className="content-title"
                                      style={{ color: "black" }}
                                    >
                                      Certificate
                                    </h3>
                                  </div>
                                </a>
                              </div>
                              <div className="cert-body">
                                <h2
                                  className="cert-body-title"
                                  style={{ fontWeight: 700, color: "#fbd9ad" }}
                                >
                                  SQL (Intermediate)
                                </h2>
                                <h3
                                  className="cert-body-subtitle"
                                  style={{
                                    color: "#eb90ff",
                                    marginBottom: "0px",
                                  }}
                                >
                                  - HackerRank
                                </h3>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                        <Col md={4} className="col-sm-12 col-md4">
                          <Fade bottom duration={2000} distance="20px">
                            <div className="cert-card">
                              <div className="content">
                                <a
                                  href={"https://www.hackerrank.com/certificates/8d41b251d35a"}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div className="content-overlay"></div>
                                  <div
                                    className="cert-header"
                                    style={{ backgroundColor: "white" }}
                                  >
                                    {
                                      <img
                                        className="logo_img"
                                        src={ucsc}
                                        alt={ucsc}
                                      />
                                    }
                                  </div>
                                  <div className="content-details fadeIn-top">
                                    <h3
                                      className="content-title"
                                      style={{ color: "black" }}
                                    >
                                      Certificate
                                    </h3>
                                  </div>
                                </a>
                              </div>
                              <div className="cert-body">
                                <h2
                                  className="cert-body-title"
                                  style={{ fontWeight: 700, color: "#fbd9ad" }}
                                >
                                  SQL (Advanced)
                                </h2>
                                <h3
                                  className="cert-body-subtitle"
                                  style={{
                                    color: "#eb90ff",
                                    marginBottom: "0px",
                                  }}
                                >
                                  - HackerRank.
                                </h3>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                        <Col md={4} className="col-sm-12 col-md4">
                          <Fade bottom duration={2000} distance="20px">
                            <div className="cert-card">
                              <div className="content">
                                <a
                                  href={"https://drive.google.com/file/d/1DRNo_qPlbbr-qg2-EpMfbmcp-fy5qBdk/view?usp=sharing"}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div className="content-overlay"></div>
                                  <div
                                    className="cert-header"
                                    style={{ backgroundColor: "white" }}
                                  >
                                    {
                                      <img
                                        className="logo_img"
                                        src={CiscoN}
                                        alt={CiscoN}
                                      />
                                    }
                                  </div>
                                  <div className="content-details fadeIn-top">
                                    <h3
                                      className="content-title"
                                      style={{ color: "black" }}
                                    >
                                      Certificate
                                    </h3>
                                  </div>
                                </a>
                              </div>
                              <div className="cert-body">
                                <h2
                                  className="cert-body-title"
                                  style={{ fontWeight: 700, color: "#fbd9ad" }}
                                >
                                  Introduction to Networks
                                </h2>
                                <h3
                                  className="cert-body-subtitle"
                                  style={{
                                    color: "#eb90ff",
                                    marginBottom: "0px",
                                  }}
                                >
                                  - Cisco Networking Academy.
                                </h3>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      <Col md={4}>
                          <Fade bottom duration={2000} distance="20px">
                            <div className="cert-card">
                              <div className="content">
                                <a
                                  href="https://drive.google.com/file/d/1kUundxOkk5gpFR5840QCpmERzfMLeg9j/view?usp=drive_link"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div className="content-overlay"></div>
                                  <div
                                    className="cert-header"
                                    style={{ backgroundColor: "white" }}
                                  >
                                    {
                                      <img
                                        className="logo_img"
                                        src={Coursera}
                                        alt={Coursera}
                                      />
                                    }
                                  </div>
                                  <div className="content-details fadeIn-top">
                                    <h3
                                      className="content-title"
                                      style={{ color: "black" }}
                                    >
                                      Certificate
                                    </h3>
                                  </div>
                                </a>
                              </div>
                              <div className="cert-body">
                                <h2
                                  className="cert-body-title"
                                  style={{ fontWeight: 700, color: "#fbd9ad" }}
                                >
                                 Foundations of User Experience (UX) Design
                                </h2>
                                <h3
                                  className="cert-body-subtitle"
                                  style={{
                                    color: "#eb90ff",
                                    marginBottom: "0px",
                                  }}
                                >
                                  - GOOGLE COURSE CERTIFICATE
                                </h3>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </Container>
            </div>
          </div>
          <div className="blog--viewAll"></div>
        </Container>
      </Container>
    </section>

    </>
      );
    }
    
        
   