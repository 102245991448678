import React from "react";
import Particle from "../../Particle";
import { Container, Row, Col } from "react-bootstrap";
import Zoom from "react-reveal/Zoom";
import { Fade } from "react-reveal";
import mp from "../../images/mp.jpg";
import fed from "../../images/fed.png";
import bkkt from "../../images/bkkt.jpg";
import nyc from "../../images/nyc.png";
import ci from "../../images/onboarding.png";
import eco from "../../images/eco.png";
import meal from "../../images/meal.png";
import ac from "../../images/academic.png";
import age from "../../images/age.png";
import calculator from "../../images/calculator.png";
import calendar from "../../images/calendar.png";
import dc from "../../images/dc.png";
import { FaCode } from "react-icons/fa";
import { Helmet } from "react-helmet";

export default function ProjectPage() {
  return (
    <section className="home-section">
      <Container fluid id="home">
        <Particle />
        <Container className="home-content">
          
        <Helmet>
        <title>Azay-Project</title>
        <meta name="description" content=" Tech Developer Nepal, Software Engineer from Nepal, AI Researcher Nepal , CSE Developer Nepal, Software Developer Nepal, Ajay Kumar Yadav Developer, Azay Yadav Portfolio, " />
        <meta name="keywords" content="Azay, Ajay kumar Yadav, Azay Yadav , Software Developer , Web Development, Full SEO,CSE Developer Nepal,Software Developer Nepal ,Ajay Kumar Yadav Developer
Azay Yadav Portfolio ,Developer from Janakpurdham , Nepalese Software Developer , Full Stack Developer Nepal
,Janakpurdham IT Professional ,React Developer Nepal, Web Developer Janakpurdham, Mobile App Developer Nepal, Software Engineer Nepal, 
Janakpur CSE Developer, Frontend Developer Nepal, Backend Developer Nepal, AI Developer Nepal, Python Developer Nepal,
 JavaScript Developer Nepal, Tech Enthusiast Nepal, Azay Yadav CSE, Ajay Kumar Yadav Developer Nepal, CSE Graduate Developer
, App Developer Nepal, Website Developer Janakpur, Full Stack Engineer Nepal, React Native Developer Nepal, AI Enthusiast Nepal, Software Solutions Nepal, Freelance Developer Nepal, 
Developer from Janakpur, Portfolio Developer Nepal, Nepal IT Projects, Software Development Nepal, Web Application Developer Nepal, Mobile Applications Nepal, Cloud Developer Nepal, Software Developer Janakpur
, Tech Innovator Nepal, IT Consultant Nepal, AI Projects Nepal, Python Expert Nepal, React Developer Portfolio, CSE Software Developer, 
IT Projects Nepal, Azay Yadav Nepal, Janakpurdham Developer Portfolio, Full Stack Web Developer
, Tech Developer Nepal, Software Engineer from Nepal, AI Researcher Nepal , CSE Developer Nepal, Software Developer Nepal, Ajay Kumar Yadav Developer, Azay Yadav Portfolio, 
Developer from Janakpurdham, Nepalese Software Developer, Full Stack Developer Nepal, Janakpurdham IT Professional, React Developer Nepal, 
Web Developer Janakpurdham, Mobile App Developer Nepal, Software Engineer Nepal, Janakpur CSE Developer, Frontend Developer Nepal, 
Backend Developer Nepal, AI Developer Nepal, Python Developer Nepal, JavaScript Developer Nepal, IEEE IoT Research Nepal, IoT Developer Nepal, 
Speech Emotion Recognition Developer, Machine Learning Developer Nepal, CSE Graduate Developer, App Developer Nepal, Website Developer Janakpur, 
React Native Developer Nepal, AI Enthusiast Nepal, Software Solutions Nepal, Freelance Developer Nepal, Developer from Janakpur,
 Portfolio Developer Nepal, Nepal IT Projects, Speech Emotion Recognition Research, IoT Projects Nepal, Full Stack Engineer Nepal, 
 Cloud Developer Nepal, Software Developer Janakpur, Tech Innovator Nepal, IT Consultant Nepal, React Developer Portfolio, Python Expert Nepal,
  J2EE Developer Nepal, Machine Learning Projects Nepal, .NET Developer Nepal, HTML CSS JavaScript Developer, 
  IEEE Paper Speech Emotion Recognition, Web Application Developer Nepal, Research on IoT Nepal, Full Stack Web Developer,
   Java Developer Nepal." />
        
        
       <title>Azay Yadav - Project</title>
       <meta name="description" content="Ajay Kumar Yadav,  a developer with a Computer Science background.blog updates Soon." />
       <meta name="keywords" content="CSE Developer, Ajay Kumar Yadav, Projects, About, Resume, Home, Blog coming soon, Developer Portfolio" />
  
       {/* Open Graph Tags for Social Sharing */}
       <meta property="og:title" content="Azay Yadav - CSE Background Developer Project" />
       <meta property="og:description" content="Ajay Kumar Yadav, also known as Azay Yadav, a developer with a Computer Science background. Explore my projects, resume, and upcoming blog." />
        <meta property="og:url" content="https://codewithazay.online/" />
        <meta property="og:image" content="https://codewithazay.online"/>

        {/* Twitter Cards for Social Media */}
       <meta name="twitter:card" content="summary_large_image" />
       <meta name="twitter:title" content="Azay Yadav - Project " />
       <meta name="twitter:description" content="Ajay Kumar Yadav, also known as Azay Yadav, developer portfolio featuring projects, resume, and blog updates." />
       <meta name="twitter:image" content="https://codewithazay.online" />

       {/* Canonical Tag */}

        </Helmet>

        

          <div
            className="d-flex justify-content-center"
            width="100%"
            style={{ backgroundColor: "#fbd9ad" }}
          >
            <Zoom left cascade>
              <h1 style={{ color: "rgb(134 61 176" }}>PROJECTS</h1>
            </Zoom>
          </div>
          <div>
            <div>
              <Container fluid className="certificate-section" id="about">
                <Container>
                  <Row>
                    <Col md={12} className="mt-5">
                      <Row className="g-5">
                        <Col md={3} className="col-sm-12 col-md-4">
                          <Fade bottom>
                            <div
                              key={1}
                              className="singleProject"
                              style={{
                                backgroundColor: "rgb(142 70 186 / 31%)",
                                border: "1px solid",
                              }}
                            >
                              <div className="projectContent">
                                <h5 id={"first"} style={{ color: "#fbd9ad" }}>
                                  New Year CountDown
                                </h5>
                                <img src={nyc} alt={nyc} />
                                <div className="project--showcaseBtn">
                                  <a
                                    href={
                                      "https://github.com/Azayyadav/Minor-Project-Web-Develop/tree/main/New-Year-Countdown"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    className={"iconBtn"}
                                    aria-labelledby={`code`}
                                  >
                                    <FaCode
                                      id={`code`}
                                      className={"icon"}
                                      aria-label="Code"
                                    />
                                  </a>
                                </div>
                              </div>
                              <h6>
                                <p
                                  className="project--desc"
                                  style={{
                                    background: "#fbd9ad",
                                    color: "#b061df",
                                    fontWeight: 600,
                                  }}
                                >
                                🎆 Countdown to a New Year, Countdown to New Dreams! 🕛

                                "With every tick of the clock, a new page turns in the book of life. 
                                 Let’s fill the coming year with love, laughter, and endless possibilities.
                                 Here's to new beginnings, fresh starts, and a year that shines brighter than ever before! 🌟"
                                </p>
                              </h6>
                              <div
                                className="project--lang"
                                style={{
                                  background: "#fbd9ad",
                                  color: "#b061df",
                                  fontWeight: 600,
                                }}
                              >
                                HTML, CSS, JavaScript
                              </div>
                            </div>
                          </Fade>
                        </Col>
                        <Col md={3} className="col-sm-12 col-md-4">
                          <Fade bottom>
                            <div
                              key={1}
                              className="singleProject"
                              style={{
                                backgroundColor: "rgb(142 70 186 / 31%)",
                                border: "1px solid",
                              }}
                            >
                              <div className="projectContent">
                                <h5 id={"first"} style={{ color: "#fbd9ad" }}>
                                Face_And_Emotion_Detection-master
                                </h5>
                                <img src={fed} alt={fed} />
                                <div className="project--showcaseBtn">
                                  <a
                                    href={
                                      "https://github.com/Azayyadav/Face_And_Emotion_Detection-master"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    className={"iconBtn"}
                                    aria-labelledby={`code`}
                                  >
                                    <FaCode
                                      id={`code`}
                                      className={"icon"}
                                      aria-label="Code"
                                    />
                                  </a>
                                </div>
                              </div>
                              <h6>
                                <p
                                  className="project--desc"
                                  style={{
                                    background: "#fbd9ad",
                                    color: "#b061df",
                                    fontWeight: 600,
                                  }}
                                >
                                  "Behind every expression lies a world of emotions waiting to be discovered.
                                   Through the lens of technology, we decode the subtleties of human emotions, 
                                   revealing the profound stories behind every smile, frown, and glance. 
                                  Let’s embrace the future where faces speak volumes and emotions are understood like never before! 🌐"
                                </p>
                              </h6>
                              <div
                                className="project--lang"
                                style={{
                                  background: "#fbd9ad",
                                  color: "#b061df",
                                  fontWeight: 600,
                                }}
                              >
                               CNN Layer,Flatten,Dropout Layers
                              </div>
                            </div>
                          </Fade>
                        </Col>
                        <Col md={3} className="col-sm-12 col-md-4">
                          <Fade bottom>
                            <div
                              key={1}
                              className="singleProjectPage"
                              style={{
                                backgroundColor: "rgb(142 70 186 / 31%)",
                                border: "1px solid",
                              }}
                            >
                              <div className="projectPageContent">
                                <h5 id={"first"} style={{ color: "#fbd9ad" }}>
                                  Online Payroll Management System
                                </h5>
                                <img src={ci} alt={ci} />
                                <div className="project--showcaseBtn">
                                  <a
                                    href={
                                      "https://github.com/Azayyadav/Online-Payroll-Management-System-main"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    className={"iconBtn"}
                                    aria-labelledby={`code`}
                                  >
                                    <FaCode
                                      id={`code`}
                                      className={"icon"}
                                      aria-label="Code"
                                    />
                                  </a>
                                </div>
                              </div>
                              <h6>
                                <p
                                  className="project--desc"
                                  style={{
                                    background: "#fbd9ad",
                                    color: "#b061df",
                                    fontWeight: 600,
                                  }}
                                >
                                 Effortlessly manage salaries, leaves, branches, and more.
                                  Generate payslips, reports, and track attendance with ease. 
                                  Plus, stay connected with our built-in chat feature. Efficiency at its best! 🌟
                                </p>
                              </h6>
                              <div
                                className="project--lang"
                                style={{
                                  background: "#fbd9ad",
                                  color: "#b061df",
                                  fontWeight: 600,
                                }}
                              >
                                HTML, CSS, JavaScript, PHP, SCSS
                              </div>
                            </div>
                          </Fade>
                        </Col>
                        <Col md={3} className="col-sm-12 col-md-4">
                          <Fade bottom>
                            <div
                              key={1}
                              className="singleProject"
                              style={{
                                backgroundColor: "rgb(142 70 186 / 31%)",
                                border: "1px solid",
                              }}
                            >
                              <div className="projectContent">
                                <h5 id={"first"} style={{ color: "#fbd9ad" }}>
                                  Shoppy Ecommerce
                                </h5>
                                <img src={eco} alt={eco} />
                                <div className="project--showcaseBtn">
                                  <a
                                    href={
                                      "https://github.com/Azayyadav/Azay_Shoppy_Ecommerce"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    className={"iconBtn"}
                                    aria-labelledby={`code`}
                                  >
                                    <FaCode
                                      id={`code`}
                                      className={"icon"}
                                      aria-label="Code"
                                    />
                                  </a>
                                </div>
                              </div>
                              <h6>
                                <p
                                  className="project--desc"
                                  style={{
                                    background: "#fbd9ad",
                                    color: "#b061df",
                                    fontWeight: 600,
                                  }}
                                >
                                  Seamless online shopping with a vast catalog,
                                  secure transactions, and personalized
                                  recommendations. 24/7 customer support,
                                  flexible payments, and fast shipping. Explore
                                  our website and redefine your shopping
                                  experience.
                                </p>
                              </h6>
                              <div
                                className="project--lang"
                                style={{
                                  background: "#fbd9ad",
                                  color: "#b061df",
                                  fontWeight: 600,
                                }}
                              >
                                HTML, CSS, React, JavaScript
                              </div>
                            </div>
                          </Fade>
                        </Col>
                        <Col md={3} className="col-sm-12 col-md-4">
                          <Fade bottom>
                            <div
                              key={1}
                              className="singleProject"
                              style={{
                                backgroundColor: "rgb(142 70 186 / 31%)",
                                border: "1px solid",
                              }}
                            >
                              <div className="projectContent">
                                <h5 id={"first"} style={{ color: "#fbd9ad" }}>
                                  Client Web Traveling 
                                </h5>
                                <img src={bkkt} alt={bkkt} />
                                <div className="project--showcaseBtn">
                                  <a
                                    href={
                                      "https://balkrishnaktravels.com/"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    className={"iconBtn"}
                                    aria-labelledby={`code`}
                                  >
                                    <FaCode
                                      id={`code`}
                                      className={"icon"}
                                      aria-label="Code"
                                    />
                                  </a>
                                </div>
                              </div>
                              <h6>
                                <p
                                  className="project--desc"
                                  style={{
                                    background: "#fbd9ad",
                                    color: "#b061df",
                                    fontWeight: 600,
                                  }}
                                >
                                  Balkrishna Travels offers a range of travel services, including personalized tours, transportation, and accommodation arrangements. 
                                  They focus on delivering a seamless travel experience tailored to client needs.
                                   For more specific details, you might want to visit their website directly or contact them for up-to-date information.
                                </p>
                              </h6>
                              <div
                                className="project--lang"
                                style={{
                                  background: "#fbd9ad",
                                  color: "#b061df",
                                  fontWeight: 600,
                                }}
                              >
                                Car Rental Website
                              </div>
                            </div>
                          </Fade>
                        </Col>
                        <Col md={3} className="col-sm-12 col-md-4">
                          <Fade bottom>
                            <div
                              key={1}
                              className="singleProject"
                              style={{
                                backgroundColor: "rgb(142 70 186 / 31%)",
                                border: "1px solid",
                              }}
                            >
                              <div className="projectContent">
                                <h5 id={"first"} style={{ color: "#fbd9ad" }}>
                                  Amazing Meal
                                </h5>
                                <img src={meal} alt={meal} />
                                <div className="project--showcaseBtn">
                                  <a
                                    href={
                                      "https://github.com/Azayyadav/Amazing_Meal-main"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    className={"iconBtn"}
                                    aria-labelledby={`code`}
                                  >
                                    <FaCode
                                      id={`code`}
                                      className={"icon"}
                                      aria-label="Code"
                                    />
                                  </a>
                                </div>
                              </div>
                              <h6>
                                <p
                                  className="project--desc"
                                  style={{
                                    background: "#fbd9ad",
                                    color: "#b061df",
                                    fontWeight: 600,
                                  }}
                                >
                                 Savor exquisite dishes crafted from fresh ingredients, 
                                 expertly prepared to delight your taste buds and
                                  keep you coming back for more.
                                </p>
                              </h6>
                              <div
                                className="project--lang"
                                style={{
                                  background: "#fbd9ad",
                                  color: "#b061df",
                                  fontWeight: 600,
                                }}
                              >
                                HTML, CSS, JavaScript, React
                              </div>
                            </div>
                          </Fade>
                        </Col>
                        <Col md={3} className="col-sm-12 col-md-4">
                          <Fade bottom>
                            <div
                              key={1}
                              className="singleProject"
                              style={{
                                backgroundColor: "rgb(142 70 186 / 31%)",
                                border: "1px solid",
                              }}
                            >
                              <div className="projectContent">
                                <h5 id={"first"} style={{ color: "#fbd9ad" }}>
                                  Academia Learning Website
                                </h5>
                                <img src={ac} alt={ac} />
                                <div className="project--showcaseBtn">
                                  <a
                                    href={
                                      "https://github.com/Azayyadav/Academia-main"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    className={"iconBtn"}
                                    aria-labelledby={`code`}
                                  >
                                    <FaCode
                                      id={`code`}
                                      className={"icon"}
                                      aria-label="Code"
                                    />
                                  </a>
                                </div>
                              </div>
                              <h6>
                                <p
                                  className="project--desc"
                                  style={{
                                    background: "#fbd9ad",
                                    color: "#b061df",
                                    fontWeight: 600,
                                  }}
                                >
                                  Unlock your potential with our dynamic
                                  learning website. Access a wide range of
                                  courses, interactive lessons, and expert
                                  instructors to enhance your knowledge and
                                  skills. Start your learning journey today and
                                  expand your horizons.
                                </p>
                              </h6>
                              <div
                                className="project--lang"
                                style={{
                                  background: "#fbd9ad",
                                  color: "#b061df",
                                  fontWeight: 600,
                                }}
                              >
                                HTML, CSS, JavaScript, React
                              </div>
                            </div>
                          </Fade>
                        </Col>
                        <Col md={3} className="col-sm-12 col-md-4">
                          <Fade bottom>
                            <div
                              key={1}
                              className="singleProject"
                              style={{
                                backgroundColor: "rgb(142 70 186 / 31%)",
                                border: "1px solid",
                              }}
                            >
                              <div className="projectContent">
                                <h5 id={"first"} style={{ color: "#fbd9ad" }}>
                                  Dual Mode Calendar
                                </h5>
                                <img src={calendar} alt={calendar} />
                                <div className="project--showcaseBtn">
                                  <a
                                    href={
                                      "https://github.com/Azayyadav/Minor-Project-Web-Develop/tree/main/Dual-Mode-Calendar"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    className={"iconBtn"}
                                    aria-labelledby={`code`}
                                  >
                                    <FaCode
                                      id={`code`}
                                      className={"icon"}
                                      aria-label="Code"
                                    />
                                  </a>
                                </div>
                              </div>
                              <h6>
                                <p
                                  className="project--desc"
                                  style={{
                                    background: "#fbd9ad",
                                    color: "#b061df",
                                    fontWeight: 600,
                                  }}
                                >
                                  The Dual Mode Calendar project is a versatile web application built with HTML, CSS, and JavaScript. It seamlessly 
                                  toggles between a traditional monthly calendar view and a sleek event scheduler. Users can effortlessly manage 
                                  appointments and events in a visually appealing and user-friendly interface.
                                </p>
                              </h6>
                              <div
                                className="project--lang"
                                style={{
                                  background: "#fbd9ad",
                                  color: "#b061df",
                                  fontWeight: 600,
                                }}
                              >
                                HTML, CSS, JavaScript
                              </div>
                            </div>
                          </Fade>
                        </Col>
                        <Col md={3} className="col-sm-12 col-md-4">
                          <Fade bottom>
                            <div
                              key={1}
                              className="singleProject"
                              style={{
                                backgroundColor: "rgb(142 70 186 / 31%)",
                                border: "1px solid",
                              }}
                            >
                              <div className="projectContent">
                                <h5 id={"first"} style={{ color: "#fbd9ad" }}>
                                  Dual Mode Calculator
                                </h5>
                                <img src={calculator} alt={calculator} />
                                <div className="project--showcaseBtn">
                                  <a
                                    href={
                                      "https://github.com/Azayyadav/Minor-Project-Web-Develop/tree/main/Dual-Mode-Calculator"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    className={"iconBtn"}
                                    aria-labelledby={`code`}
                                  >
                                    <FaCode
                                      id={`code`}
                                      className={"icon"}
                                      aria-label="Code"
                                    />
                                  </a>
                                </div>
                              </div>
                              <h6>
                                <p
                                  className="project--desc"
                                  style={{
                                    background: "#fbd9ad",
                                    color: "#b061df",
                                    fontWeight: 600,
                                  }}
                                >
                                  The Dual Mode Calculator project is a web application created with HTML, CSS, and JavaScript. It offers two modes: 
                                  basic and scientific, providing essential arithmetic functions and advanced calculations. The user-friendly interface 
                                  and responsive design ensure seamless use across various devices.
                                </p>
                              </h6>
                              <div
                                className="project--lang"
                                style={{
                                  background: "#fbd9ad",
                                  color: "#b061df",
                                  fontWeight: 600,
                                }}
                              >
                                HTML, CSS, JavaScript
                              </div>
                            </div>
                          </Fade>
                        </Col>
                        <Col md={3} className="col-sm-12 col-md-4">
                          <Fade bottom>
                            <div
                              key={1}
                              className="singleProject"
                              style={{
                                backgroundColor: "rgb(142 70 186 / 31%)",
                                border: "1px solid",
                              }}
                            >
                              <div className="projectContent">
                                <h5 id={"first"} style={{ color: "#fbd9ad" }}>
                                  Digital Clock
                                </h5>
                                <img src={dc} alt={dc} />
                                <div className="project--showcaseBtn">
                                  <a
                                    href={
                                      "https://github.com/Azayyadav/Minor-Project-Web-Develop/tree/main/Digital-Clock"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    className={"iconBtn"}
                                    aria-labelledby={`code`}
                                  >
                                    <FaCode
                                      id={`code`}
                                      className={"icon"}
                                      aria-label="Code"
                                    />
                                  </a>
                                </div>
                              </div>
                              <h6>
                                <p
                                  className="project--desc"
                                  style={{
                                    background: "#fbd9ad",
                                    color: "#b061df",
                                    fontWeight: 600,
                                  }}
                                >
                                  Create a sleek, responsive digital clock with HTML, CSS, and JavaScript. Display real-time hours, minutes, and seconds, 
                                  and update automatically. Customize its style and layout using CSS for a modern, functional timekeeping experience.
                                </p>
                              </h6>
                              <div
                                className="project--lang"
                                style={{
                                  background: "#fbd9ad",
                                  color: "#b061df",
                                  fontWeight: 600,
                                }}
                              >
                                HTML, CSS, JavaScript
                              </div>
                            </div>
                          </Fade>
                        </Col>
                        <Col md={3} className="col-sm-12 col-md-4">
                          <Fade bottom>
                            <div
                              key={1}
                              className="singleProject"
                              style={{
                                backgroundColor: "rgb(142 70 186 / 31%)",
                                border: "1px solid",
                              }}
                            >
                              <div className="projectContent">
                                <h5 id={"first"} style={{ color: "#fbd9ad" }}>
                                  Age Calculator
                                </h5>
                                <img src={age} alt={age} />
                                <div className="project--showcaseBtn">
                                  <a
                                    href={
                                      "https://github.com/Azayyadav/Minor-Project-Web-Develop/tree/main/Age-Calculator"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    className={"iconBtn"}
                                    aria-labelledby={`code`}
                                  >
                                    <FaCode
                                      id={`code`}
                                      className={"icon"}
                                      aria-label="Code"
                                    />
                                  </a>
                                </div>
                              </div>
                              <h6>
                                <p
                                  className="project--desc"
                                  style={{
                                    background: "#fbd9ad",
                                    color: "#b061df",
                                    fontWeight: 600,
                                  }}
                                >
                                  Create an Age Calculator project using HTML, CSS, and JavaScript. Input your birthdate, and the tool 
                                  instantly calculates your current age. The sleek design and user-friendly interface make age 
                                  determination quick and hassle-free. Perfect for websites, apps, or personal use.
                                </p>
                              </h6>
                              <div
                                className="project--lang"
                                style={{
                                  background: "#fbd9ad",
                                  color: "#b061df",
                                  fontWeight: 600,
                                }}
                              >
                                HTML, CSS, JavaScript
                              </div>
                            </div>
                          </Fade>
                        </Col>
                        <Col md={3} className="col-sm-12 col-md-4">
                          <Fade bottom>
                            <div
                              key={1}
                              className="singleProject"
                              style={{
                                backgroundColor: "rgb(142 70 186 / 31%)",
                                border: "1px solid",
                              }}
                            >
                              <div className="projectContent">
                                <h5 id={"first"} style={{ color: "#fbd9ad" }}>
                                 Music player
                                </h5>
                                <img src={mp} alt={mp} />
                                <div className="project--showcaseBtn">
                                  <a
                                    href={
                                      "https://github.com/Azayyadav/Reactmusicplayersolution"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    className={"iconBtn"}
                                    aria-labelledby={`code`}
                                  >
                                    <FaCode
                                      id={`code`}
                                      className={"icon"}
                                      aria-label="Code"
                                    />
                                  </a>
                                </div>
                              </div>
                              <h6>
                                <p
                                  className="project--desc"
                                  style={{
                                    background: "#fbd9ad",
                                    color: "#b061df",
                                    fontWeight: 600,
                                  }}
                                >
                                  React Music Player Solution provides an elegant and customizable music player built with React. 
                                  It offers a seamless and interactive experience for users to enjoy their favorite tracks, with features like playlist management,
                                   playback controls, and responsive design. 
                                  Ideal for integrating into music-related apps or websites.
                                </p>
                              </h6>
                              <div
                                className="project--lang"
                                style={{
                                  background: "#fbd9ad",
                                  color: "#b061df",
                                  fontWeight: 600,
                                }}
                              >
                                React
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </Container>
            </div>
          </div>
        </Container>
      </Container>
    </section>
  );
}
