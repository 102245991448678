import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <div>
      <Container>
      <Helmet>
        <title>Azay Yadav - Software Engineer</title>
        <meta name="description" content="I am passionate about exploring the vast and ever-evolving world of technology. I have a strong foundation in Data Structures and Algorithms, and I am constantly working to sharpen my skills in Data structures with various algorithms. Along with this, I am also enthusiastic about Web Development and am dedicatedly learning the latest technologies and tools in this field." />
        <meta name="keywords" content="Azay, Ajay kumar Yadav, Azay Yadav , Software Developer , Web Development, Full SEO,CSE Developer Nepal,Software Developer Nepal ,Ajay Kumar Yadav Developer
Azay Yadav Portfolio ,Developer from Janakpurdham , Nepalese Software Developer , Full Stack Developer Nepal
,Janakpurdham IT Professional ,React Developer Nepal, Web Developer Janakpurdham, Mobile App Developer Nepal, Software Engineer Nepal, 
Janakpur CSE Developer, Frontend Developer Nepal, Backend Developer Nepal, AI Developer Nepal, Python Developer Nepal,
 JavaScript Developer Nepal, Tech Enthusiast Nepal, Azay Yadav CSE, Ajay Kumar Yadav Developer Nepal, CSE Graduate Developer
, App Developer Nepal, Website Developer Janakpur, Full Stack Engineer Nepal, React Native Developer Nepal, AI Enthusiast Nepal, Software Solutions Nepal, Freelance Developer Nepal, 
Developer from Janakpur, Portfolio Developer Nepal, Nepal IT Projects, Software Development Nepal, Web Application Developer Nepal, Mobile Applications Nepal, Cloud Developer Nepal, Software Developer Janakpur
, Tech Innovator Nepal, IT Consultant Nepal, AI Projects Nepal, Python Expert Nepal, React Developer Portfolio, CSE Software Developer, 
IT Projects Nepal, Azay Yadav Nepal, Janakpurdham Developer Portfolio, Full Stack Web Developer
, Tech Developer Nepal, Software Engineer from Nepal, AI Researcher Nepal , CSE Developer Nepal, Software Developer Nepal, Ajay Kumar Yadav Developer, Azay Yadav Portfolio, 
Developer from Janakpurdham, Nepalese Software Developer, Full Stack Developer Nepal, Janakpurdham IT Professional, React Developer Nepal, 
Web Developer Janakpurdham, Mobile App Developer Nepal, Software Engineer Nepal, Janakpur CSE Developer, Frontend Developer Nepal, 
Backend Developer Nepal, AI Developer Nepal, Python Developer Nepal, JavaScript Developer Nepal, IEEE IoT Research Nepal, IoT Developer Nepal, 
Speech Emotion Recognition Developer, Machine Learning Developer Nepal, CSE Graduate Developer, App Developer Nepal, Website Developer Janakpur, 
React Native Developer Nepal, AI Enthusiast Nepal, Software Solutions Nepal, Freelance Developer Nepal, Developer from Janakpur,
 Portfolio Developer Nepal, Nepal IT Projects, Speech Emotion Recognition Research, IoT Projects Nepal, Full Stack Engineer Nepal, 
 Cloud Developer Nepal, Software Developer Janakpur, Tech Innovator Nepal, IT Consultant Nepal, React Developer Portfolio, Python Expert Nepal,
  J2EE Developer Nepal, Machine Learning Projects Nepal, .NET Developer Nepal, HTML CSS JavaScript Developer, 
  IEEE Paper Speech Emotion Recognition, Web Application Developer Nepal, Research on IoT Nepal, Full Stack Web Developer,
   Java Developer Nepal." />
        
        
       <title>Azay Yadav - Introduction</title>
       <meta name="description" content="Ajay Kumar Yadav,  a developer with a Computer Science background.blog updates Soon." />
       <meta name="keywords" content="CSE Developer, Ajay Kumar Yadav, Projects, About, Resume, Home, Blog coming soon, Developer Portfolio" />
  
       {/* Open Graph Tags for Social Sharing */}
       <meta property="og:title" content="Azay Yadav - CSE Background Developer Portfolio" />
       <meta property="og:description" content="Ajay Kumar Yadav, also known as Azay Yadav, a developer with a Computer Science background. Explore my projects, resume, and upcoming blog." />
        <meta property="og:url" content="https://codewithazay.online/" />
        <meta property="og:image" content="https://codewithazay.online"/>

        {/* Twitter Cards for Social Media */}
       <meta name="twitter:card" content="As a motivated and driven student currently pursuing B.TECH in CSE, I am passionate about exploring the vast and ever-evolving world of technology. I have a strong foundation in Data Structures and Algorithms, and I am constantly working to sharpen my skills in Data structures with various algorithms. Along with this, I am also enthusiastic about Web Development and am dedicatedly learning the latest technologies and tools in this field." />
       <meta name="twitter:title" content="Azay Yadav - Introduction " />
       <meta name="twitter:description" content="Ajay Kumar Yadav, also known as Azay Yadav, developer featuring projects, resume, and blog updates." />
       <meta name="twitter:image" content="https://codewithazay.online" />

    
        </Helmet>

        <Row className="mt-5">
          <Col md={5}>
            <Slide left>
              <lottie-player
                src="https://assets5.lottiefiles.com/packages/lf20_iv4dsx3q.json"
                background="transparent"
                speed="1"
                style={{ width: "100%", height: "90%" }}
                loop
                autoplay
              ></lottie-player>
            </Slide>
          </Col>

          <Col md={7}>
            <Fade duration={3000}>
              <p className="home-about-body">
                Hello! 👋 My name is <b className="purple">Ajay Kumar Yadav</b>, and I am a passionate 
                <b className="purple"> Computer Science and Engineering</b> graduate. 💻
                <br />
                <br />
                I have expertise in various programming languages including:
                <b className="purple">
                  {" "}
                  ReactJS, React-Native, Java, J2EE, JavaScript, LSTM (Long Short-Term Memory), RNN, CNN, .Net, and C#. 
                </b>{" "} 🚀
                <br />
                <br />
                I am also experienced with powerful frameworks like:
                <b className="purple"> 
                  {" "}NodeJS, ReactJS, OpenCV, Pandas, and NumPy.{" "}
                </b>{" "}⚙️
                <br />
                <br />
                Whenever I can, I love to develop innovative products using 
                <b className="purple">{" "}modern React libraries and frameworks.{" "}</b> 🛠️
                <br />
                <br />
                In my free time, I enjoy learning about new technologies, and 
                <b className="purple">{" "}building web apps and digital products.{" "}</b> 🎯✨
              </p>
            </Fade>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default About;
