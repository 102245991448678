import React, { useEffect, useState } from 'react';

export default function Preloader() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if preloader has been shown in the current session
    const hasShownPreloader = sessionStorage.getItem('hasShownPreloader');

    if (!hasShownPreloader) {
      // Show the preloader
      setIsVisible(true);
      // Set a flag in sessionStorage to not show it again in this session
      sessionStorage.setItem('hasShownPreloader', 'true');

      // Hide the preloader after a delay (e.g., 2 seconds)
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 2000); // Change duration as needed

      // Clear the timeout on component unmount
      return () => clearTimeout(timer);
    } else {
      // If it has been shown, ensure it's not visible
      setIsVisible(false);
    }
  }, []);

  return (
    <div id={isVisible ? "preloader" : "preloader-none"}>
      {/* Preloader content here */}
    </div>
  );
}
