import  { useEffect } from "react";

const ChatlingBot = () => {
  useEffect(() => {
    // Create the script element
    const script1 = document.createElement("script");
    script1.async = true;
    script1.type = "text/javascript";
    script1.innerHTML = `window.chtlConfig = { chatbotId: "6229271491" };`;
    
    const script2 = document.createElement("script");
    script2.src = "https://chatling.ai/js/embed.js";
    script2.async = true;
    script2.setAttribute("data-id", "6229271491");
    script2.id = "chatling-embed-script";

    // Append both scripts to the document body
    document.body.appendChild(script1);
    document.body.appendChild(script2);

    // Clean up by removing the script tags on component unmount
    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, []);

  return null; // No UI element is needed for this
};

export default ChatlingBot;
