import React, { useState, useEffect } from 'react';
import './Buttonizer.css';
import plusIcon from '../../images/social png/phone-call.png'; 
import closeIcon from '../../images/social png/phone-call.png'; 
import githubIcon from '../../images/social png/github.png';
import messengerIcon from '../../images/social png/messenger.png';
import whatsappIcon from '../../images/social png/whatsapp.png';
import instaIcon from '../../images/social png/insta.jpg';
import linkedinIcon from '../../images/social png/linkedin_.png';
import emailIcon from '../../images/social png/gmail.png';
import youtubeIcon from '../../images/social png/youtube.png';
import { Helmet } from 'react-helmet';

const Buttonizer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    let timer;
    if (isOpen) {
      timer = setTimeout(() => {
        setIsOpen(false);
      }, 3000); // Auto-close after 5 seconds
    }
    return () => clearTimeout(timer); // Cleanup the timer if the component unmounts or `isOpen` changes
  }, [isOpen]);

  return (
    <div className="buttonizer-container">
      <Helmet>
        <title>Azay Yadav - Social Media</title>
        <meta name="description" content="Azay Yadav Portfolio ,Developer from Janakpurdham , Nepalese Software Developer , Full Stack Developer Nepal" />
        <meta name="keywords" content="Azay, Ajay kumar Yadav, Azay Yadav , Software Developer , Web Development, Full SEO,CSE Developer Nepal,Software Developer Nepal ,Ajay Kumar Yadav Developer
Azay Yadav Portfolio ,Developer from Janakpurdham , Nepalese Software Developer , Full Stack Developer Nepal
,Janakpurdham IT Professional ,React Developer Nepal, Web Developer Janakpurdham, Mobile App Developer Nepal, Software Engineer Nepal, 
Janakpur CSE Developer, Frontend Developer Nepal, Backend Developer Nepal, AI Developer Nepal, Python Developer Nepal,
 JavaScript Developer Nepal, Tech Enthusiast Nepal, Azay Yadav CSE, Ajay Kumar Yadav Developer Nepal, CSE Graduate Developer
, App Developer Nepal, Website Developer Janakpur, Full Stack Engineer Nepal, React Native Developer Nepal, AI Enthusiast Nepal, Software Solutions Nepal, Freelance Developer Nepal, 
Developer from Janakpur, Portfolio Developer Nepal, Nepal IT Projects, Software Development Nepal, Web Application Developer Nepal, Mobile Applications Nepal, Cloud Developer Nepal, Software Developer Janakpur
, Tech Innovator Nepal, IT Consultant Nepal, AI Projects Nepal, Python Expert Nepal, React Developer Portfolio, CSE Software Developer, 
IT Projects Nepal, Azay Yadav Nepal, Janakpurdham Developer Portfolio, Full Stack Web Developer
, Tech Developer Nepal, Software Engineer from Nepal, AI Researcher Nepal , CSE Developer Nepal, Software Developer Nepal, Ajay Kumar Yadav Developer, Azay Yadav Portfolio, 
Developer from Janakpurdham, Nepalese Software Developer, Full Stack Developer Nepal, Janakpurdham IT Professional, React Developer Nepal, 
Web Developer Janakpurdham, Mobile App Developer Nepal, Software Engineer Nepal, Janakpur CSE Developer, Frontend Developer Nepal, 
Backend Developer Nepal, AI Developer Nepal, Python Developer Nepal, JavaScript Developer Nepal, IEEE IoT Research Nepal, IoT Developer Nepal, 
Speech Emotion Recognition Developer, Machine Learning Developer Nepal, CSE Graduate Developer, App Developer Nepal, Website Developer Janakpur, 
React Native Developer Nepal, AI Enthusiast Nepal, Software Solutions Nepal, Freelance Developer Nepal, Developer from Janakpur,
 Portfolio Developer Nepal, Nepal IT Projects, Speech Emotion Recognition Research, IoT Projects Nepal, Full Stack Engineer Nepal, 
 Cloud Developer Nepal, Software Developer Janakpur, Tech Innovator Nepal, IT Consultant Nepal, React Developer Portfolio, Python Expert Nepal,
  J2EE Developer Nepal, Machine Learning Projects Nepal, .NET Developer Nepal, HTML CSS JavaScript Developer, 
  IEEE Paper Speech Emotion Recognition, Web Application Developer Nepal, Research on IoT Nepal, Full Stack Web Developer,
   Java Developer Nepal." />
        
        
       <title>Azay Yadav - Social Media</title>
       <meta name="description" content="Ajay Kumar Yadav,  a developer with a Computer Science background.blog updates Soon." />
       <meta name="keywords" content="CSE Developer, Ajay Kumar Yadav, Projects, About, Resume, Home, Blog coming soon, Developer Portfolio" />
  
       {/* Open Graph Tags for Social Sharing */}
       <meta property="og:title" content="Azay Yadav - CSE Background Developer Portfolio" />
       <meta property="og:description" content="Ajay Kumar Yadav, also known as Azay Yadav, a developer with a Computer Science background. Explore my projects, resume, and upcoming blog." />
        <meta property="og:url" content="https://codewithazay.online/" />
        <meta property="og:image" content="https://codewithazay.online"/>

        {/* Twitter Cards for Social Media */}
       <meta name="twitter:card" content="summary_large_image" />
       <meta name="twitter:title" content="Azay Yadav  - Social Media " />
       <meta name="twitter:description" content="Ajay Kumar Yadav, also known as Azay Yadav, developer portfolio featuring projects, resume, and blog updates." />
       <meta name="twitter:image" content="https://codewithazay.online" />

        </Helmet>
 
      <div className={`buttonizer-toggle ${isOpen ? 'open' : ''}`} onClick={handleToggle}>
        <img src={isOpen ? closeIcon : plusIcon} alt={isOpen ? 'Close' : 'Open'} />
      </div>
      <div className={`buttonizer-group ${isOpen ? 'open' : ''}`}>
        <div className="buttonizer">
          <a href="https://github.com/azayyadav" target="_blank" rel="noopener noreferrer">
            <img src={githubIcon} alt="github" />
          </a>
        </div>
        <div className="buttonizer">
          <a href="https://www.facebook.com/AzayYaduvanshi" target="_blank" rel="noopener noreferrer">
            <img src={messengerIcon} alt="Messenger" />
          </a>
        </div>
        <div className="buttonizer">
          <a href="https://chat.whatsapp.com/IK0940WGZqQ58HGjDqpGCy" target="_blank" rel="noopener noreferrer">
            <img src={whatsappIcon} alt="WhatsApp" />
          </a>
        </div>
        <div className="buttonizer">
          <a href="https://www.instagram.com/azayyaduvanshi/">
            <img src={instaIcon} alt="Instagram" />
          </a>
        </div>
        <div className="buttonizer">
          <a href="https://www.linkedin.com/in/ajaykumaryadav01">
            <img src={linkedinIcon} alt="linkedin" />
          </a>
        </div>
        <div className="buttonizer">
          <a href="mailto:azaykumaryadav1@gmail.com">
            <img src={emailIcon} alt="Email" />
          </a>
        </div>
        <div className="buttonizer">
          <a href="https://www.youtube.com/@journeyroom">
            <img src={youtubeIcon} alt="Email" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Buttonizer;
