import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import vs from "../../../src/images/Vs.png";
import pc from "../../../src/images/pc.png";
import git from "../../../src/images/git.png";
import github from "../../../src/images/github.png";
import cb from "../../../src/images/cb.png";
import pg from "../../../src/images/pg.png";
import pm from "../../../src/images/pm.png";
import chrome from "../../../src/images/chorme.svg";
import Zoom from "react-reveal/Zoom";

export default function Toolkit() {
  return (
    <div>
          <Zoom left cascade>
          <h1
  className="mt-4"
  style={{
    backgroundSize: '800% 400%',
    color: 'white',
    animation: 'backgroundAnimation 8s linear infinite',
    padding: '10px',
    borderRadius: '5px',
    textAlign: 'center'
  }}
>
  Tools I Use
</h1>
      </Zoom>
      <div className="mt-5 d-flex flex-row justify-content-center">
      </div>
      <Container className="mt-4">
        <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
          <Col xs={4} md={2} className="tech-icons">
            <img src={vs} alt="" style={{ width: "85%", height: "85%" }} />
          </Col>
          <Col xs={4} md={2} className="tech-icons">
            <img src={pc} alt="" style={{ width: "85%", height: "85%" }} />
          </Col>
          <Col xs={4} md={2} className="tech-icons">
            <img src={git} alt="" style={{ width: "85%", height: "85%" }} />
          </Col>
          <Col xs={4} md={2} className="tech-icons">
            <img
              src={github}
              alt=""
              style={{
                width: "85%",
                height: "85%",
                filter: "grayscale(1) invert(1)",
              }}
            />
          </Col>
          <Col xs={4} md={2} className="tech-icons">
            <img src={cb} alt="" style={{ width: "95%", height: "95%" }} />
          </Col>
          <Col xs={4} md={2} className="tech-icons">
            <img src={pg} alt="" style={{ width: "95%", height: "95%" }} />
          </Col>
          <Col xs={4} md={2} className="tech-icons">
            <img src={pm} alt="" style={{ width: "95%", height: "95%" }} />
          </Col>
          <Col xs={4} md={2} className="tech-icons">
            <img src={chrome} alt="" style={{ width: "95%", height: "95%" }} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
